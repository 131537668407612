<!--
 * @Descripttion : 供应商总部
 * @version      : 
 * @Author       : ml
 * @Date         : 2020-09-07 17:06:48
 * @LastEditors  : ml
 * @LastEditTime : 2020-09-14 10:26:40
-->
<template>
  <div>
    <div style="padding:10px 0;">
      <a-form-model layout="inline" :model="formInline" @submit.native.prevent>
        <a-form-model-item label="是否禁用">
          <a-select v-model="formInline.isDisable" placeholder="是否禁用" style="width:70px;" @change="getList">
            <a-select-option value="">
              全部
            </a-select-option>
            <a-select-option :value="0">
              否
            </a-select-option>
            <a-select-option :value="1">
              是
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-input-search v-model="formInline.keyWord" placeholder="编号/名称/联系人/联系人电话" allowClear enter-button @search="getList" />
        </a-form-model-item>
        <a-form-model-item v-if="permissions.indexOf('supplierHeadquart:add')>-1">
          <a-button type="primary" icon="plus" @click="addSupplierHead" />
        </a-form-model-item>
      </a-form-model>
    </div>

    <a-table :columns="columns" bordered :data-source="data" :rowKey='record=>record.id' :pagination="pagination" :loading="loading" @change="handleTableChange" size="middle">
      <template slot="contact" slot-scope="record">
        <div>
          联系人：{{record.contact}}
        </div>
        <div>
          联系电话：{{record.phone}}
        </div>
      </template>
      <template slot="address" slot-scope="record">
        {{record.provinceName}} {{record.cityName}} {{record.areaName}} {{record.detailAdress}}
      </template>
      <template slot="isDisable" slot-scope="record">
        {{record.isDisable==0?'否':'是'}}
      </template>
      <template slot="operation" slot-scope="record">
        <a-space>
          <a-button type="primary" size="small" v-if="permissions.indexOf('supplierHeadquart:edit')>-1" icon="edit" @click="editSupplierHead(record)" />
          <a-button type="danger" size="small" v-if="permissions.indexOf('supplierHeadquart:delete')>-1" icon="delete" @click="deleteSupplierHead(record)" />
        </a-space>
      </template>
    </a-table>

    <!-- 新增/编辑 -->
    <a-drawer :title="title" :width="300" :visible="visibleAddEdit" @close="onClose">
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <a-form-model-item label="供应商总部名称" prop="name">
          <a-input v-model="form.name" placeholder="请输入供应商总部名称" />
        </a-form-model-item>
        <a-form-model-item label="联系人" prop="contact">
          <a-input v-model="form.contact" placeholder="请输入联系人" />
        </a-form-model-item>
        <a-form-model-item label="联系电话" prop="phone">
          <a-input v-model="form.phone" placeholder="请输入联系电话" />
        </a-form-model-item>
        <a-form-model-item label="省/市" prop="telephone">
          <selectProvinceCity :selectedProvinceCity="provinceCitySelected" @change="onChange"></selectProvinceCity>
        </a-form-model-item>
        <a-form-model-item label="详细地址" prop="detailAdress">
          <a-input v-model="form.detailAdress" type="textarea" placeholder="请输入详细地址" />
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remark">
          <a-input v-model="form.remark" type="textarea" placeholder="请输入备注" />
        </a-form-model-item>
        <a-form-model-item label="是否禁用" prop="formDisable">
          <a-switch v-model="form.formDisable" checked-children="是" un-checked-children="否" />
        </a-form-model-item>
      </a-form-model>
      <div class="ml_drawer_footer">
        <a-button @click="onClose">
          取消
        </a-button>
        <a-button style="margin-left: 10px" type="primary" @click="onSubmit">
          确定
        </a-button>
      </div>
    </a-drawer>

  </div>
</template>
<script>
import {
  addSupplierHead,
  updateSupplierHead,
  getSupplierHeadByID,
  deleteSupplierHead,
  getSupplierHeadList
} from "@/api/supplierHead";

import { mapState } from "vuex";

import { validatePhone } from "@/utils/verification";

import selectProvinceCity from "@/components/component-province-city/component-province-city";

const columns = [
  { title: "供应商总部编号", dataIndex: "number", align: "center" },
  { title: "供应商总部名称", dataIndex: "name", align: "center" },
  { title: "联系人", scopedSlots: { customRender: "contact" } },
  { title: "地址", scopedSlots: { customRender: "address" }, align: "center" },
  {
    title: "是否禁用",
    scopedSlots: { customRender: "isDisable" },
    align: "center"
  },
  { title: "备注", dataIndex: "remark" },
  { title: "修改时间", dataIndex: "updateTime", align: "center" },
  {
    title: "操作",
    scopedSlots: { customRender: "operation" },
    align: "center"
  }
];

export default {
  components: {
    selectProvinceCity
  },
  data() {
    return {
      columns,
      data: [],
      loading: false,
      pagination: {
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40"],
        showSizeChanger: true,
        current: 1,
        total: 0
      },
      visibleAddEdit: false, //  是否显示操作
      formInline: {
        keyWord: "",
        isDisable: ""
      },
      title: "",
      form: {
        areaCode: "",
        areaName: "",
        cityCode: "",
        cityName: "",
        contact: "",
        detailAdress: "",
        id: "",
        isDisable: false,
        formDisable: false,
        name: "",
        operateCity: "",
        operateIp: "",
        operateUserName: "",
        phone: "",
        provinceCode: "",
        provinceName: "",
        remark: "",
        source: ""
      },
      rules: {
        name: [{ required: true, message: "请输入供应商总部名称" }],
        contact: [{ required: true, message: "请输入联系人" }],
        phone: [{ validator: validatePhone, trigger: "change" }]
      },
      provinceCitySelected: []
    };
  },
  computed: {
    ...mapState({
      permissions: state => state.userStore.permissions
    })
  },
  mounted() {
    this.getList();
  },
  methods: {
    onChange(selectedOptions) {
      this.form.provinceCode = parseInt(selectedOptions[0].id);
      this.form.provinceName = selectedOptions[0].name;
      this.form.cityCode = parseInt(selectedOptions[1].id);
      this.form.cityName = selectedOptions[1].name;
      this.form.areaCode = parseInt(selectedOptions[2].id);
      this.form.areaName = selectedOptions[2].name;
    },

    handleTableChange(pagination, filters, sorter) {
      console.log(pagination, filters, sorter);
      this.pagination = { ...pagination };
      this.getList();
    },
    getList() {
      const that = this;
      that.loading = true;
      getSupplierHeadList({
        keyWord: that.formInline.keyWord,
        isDisable: that.formInline.isDisable,
        pageNum: that.pagination.current,
        pageSize: that.pagination.pageSize,
        _: new Date().getTime()
      })
        .then(res => {
          that.loading = false;
          that.data = res.data.list;
          that.pagination.total = res.data.total;
        })
        .catch(err => {
          console.log(err);
          that.loading = false;
        });
    },
    onClose() {
      this.visibleAddEdit = false;
    },
    addSupplierHead() {
      //   新增
      const that = this;
      that.visibleAddEdit = true;
      that.form.id = "";
      that.title = "新增供应商总部";
      that.$nextTick(() => {
        that.$refs.ruleForm.resetFields();
      });
      that.provinceCitySelected = [];
    },
    editSupplierHead(e) {
      // 编辑
      const that = this;
      that.visibleAddEdit = true;
      that.form.id = e.id;
      that.title = "编辑供应商总部";
      that.provinceCitySelected = [];

      getSupplierHeadByID(e.id).then(res => {
        if (res.data.isDisable === 1) {
          res.data.formDisable = true;
        } else {
          res.data.formDisable = false;
        }
        delete res.data.updateTime;
        that.form = { ...res.data };

        that.provinceCitySelected.push(that.form.provinceCode);
        that.provinceCitySelected.push(that.form.cityCode);
        that.provinceCitySelected.push(parseInt(that.form.areaCode));
      });
    },
    onSubmit() {
      const that = this;

      that.$refs.ruleForm.validate(valid => {
        if (valid) {
          that.form["json"] = true;
          if (that.form.formDisable) {
            that.form.isDisable = 1;
          } else {
            that.form.isDisable = 0;
          }
          if (that.form.id) {
            updateSupplierHead(that.form).then(() => {
              that.$message.success({
                content: "修改成功！",
                duration: 1,
                onClose: () => {
                  that.visibleAddEdit = false;
                  that.getList();
                }
              });
            });
          } else {
            addSupplierHead(that.form).then(() => {
              that.$message.success({
                content: "添加成功！",
                duration: 1,
                onClose: () => {
                  that.visibleAddEdit = false;
                  that.getList();
                }
              });
            });
          }
        }
      });
    },
    deleteSupplierHead(e) {
      const that = this;
      that.$confirm({
        title: "是否确认删除该供应商总部?",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        centered: true,
        onOk() {
          deleteSupplierHead(e.id).then(() => {
            that.$message.success({
              content: "删除成功！",
              duration: 1,
              onClose: () => {
                that.getList();
              }
            });
          });
        },
        onCancel() {
          console.log("Cancel");
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
</style>